
import { Component, Vue } from "vue-property-decorator";
import SongPreview from './SongPreview.vue';
import FButton from '@/components/Button/FButton.vue'
import { RegisterSongService } from "@/services/RegisterSongService";
import FForm from "@/components/Form/FForm.vue";
import Alert from '@/components/Alert/Alert.vue';
import RegisterSongStepButtons from './RegisterSongStepButtons.vue';
import { ToastService } from "@/services/ToastService";
import { i18n } from "@/main";
import { CustomError } from "@/models/CustomError";

@Component({
  components:{
    SongPreview,
    FButton,
    FForm,
    Alert,
    RegisterSongStepButtons
  },
})
export default class RegisterSongStep3ReviewAndRegisterSong extends Vue {

  store = RegisterSongService;
  serverError = '';

  async onSubmit(){
    this.serverError = '';
    if ((this.$refs.form as FForm).hasFormError){
      return;
    }
    try{
      await this.store.submitSong();
    }catch(err){
      if ((err as any)?.data?.publicStatusMessage){
        this.serverError = (err as any).data.publicStatusMessage;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }else{
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        throw new CustomError(err, false, 'Reason code not recognized');
      }
    }
  }
}
