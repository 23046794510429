import { render, staticRenderFns } from "./RegisterSongStep3ReviewAndRegisterSong.vue?vue&type=template&id=90b25fc8&scoped=true"
import script from "./RegisterSongStep3ReviewAndRegisterSong.vue?vue&type=script&lang=ts"
export * from "./RegisterSongStep3ReviewAndRegisterSong.vue?vue&type=script&lang=ts"
import style0 from "./RegisterSongStep3ReviewAndRegisterSong.vue?vue&type=style&index=0&id=90b25fc8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90b25fc8",
  null
  
)

export default component.exports