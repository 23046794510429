import { render, staticRenderFns } from "./RegisterSongStep.vue?vue&type=template&id=61ba0b81&scoped=true"
import script from "./RegisterSongStep.vue?vue&type=script&lang=ts"
export * from "./RegisterSongStep.vue?vue&type=script&lang=ts"
import style0 from "./RegisterSongStep.vue?vue&type=style&index=0&id=61ba0b81&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ba0b81",
  null
  
)

export default component.exports