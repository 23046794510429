
import { Component, Prop, Vue } from "vue-property-decorator";
import FSelect from "@/components/Form/FSelect.vue";
import ChordProEditor from '@/components/ChordProEditor/ChordProEditor.vue';
import FInput from "@/components/Form/FInput.vue";
import FCheckbox from "@/components/Form/FCheckbox.vue";
import FTextarea from "@/components/Form/FTextarea.vue";
import FForm from "@/components/Form/FForm.vue";
import PhraseLink from '@/components/PhraseLink/PhraseLink.vue';
import FButton from "@/components/Button/FButton.vue";
import DraggableContainer from '@/components/Draggable/DraggableContainer.vue';
import Modal from '@/components/Modal/Modal.vue';
import ModalForm from '@/components/Modal/ModalForm.vue';
import Alert from '@/components/Alert/Alert.vue';
import HelpToggle from '@/components/HelpToggle/HelpToggle.vue';
import RegisterSongStepButtons from './RegisterSongStepButtons.vue';
import ChordProPreview from '@/components/ChordProEditor/ChordProPreview.vue'
import { RegisterSongService } from "@/services/RegisterSongService";
import { bus, i18n } from "@/main";
import { CustomError } from "@/models/CustomError";
import { ToastService } from "@/services/ToastService";
import EditSongChordsModal from "../Shared/EditSongChordsModal.vue";
import { GTMService } from "@/services/GTMService";
import { BusyService } from "@/services/BusyService";
import SongChordEditorModal from "../Shared/SongChordEditorModal.vue";


@Component({
  components: {
    HelpToggle,
    FSelect,
    FForm,
    FButton,
    FCheckbox,
    FInput,
    FTextarea,
    Modal,
    DraggableContainer,
    ModalForm,
    PhraseLink,
    Alert,
    RegisterSongStepButtons,
    ChordProEditor,
    ChordProPreview,
    EditSongChordsModal
  },
})
export default class RegisterSongStep2bEnterChords extends Vue {
  store = RegisterSongService;
  error = false;

  mounted() {
    if (this.store.checkChordsDiffer){
      this.store.checkChordsDiffer = false;
      if (this.store.data && this.store.data.chords && !this.store.editMode && RegisterSongService.lyricsDiffer){
        this.store.requireEditChordsAfter = true;
        this.editChords();
      }
    }
  }

  get editMode() {
    return this.store.editMode;
  }

  async onSubmit() {
    if ((this.$refs.form as FForm).hasFormError) {
      return;
    }

    if (!this.store.data?.chords){
      GTMService.pushCustomEvent({event: 'registerSongSkipEnterChords'});
    }

    if (this.editMode) {
      this.$emit('submit');
    } else {
      try {
        await this.store.saveAndContinue();
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  async handleError(err: any) {
    this.error = true;
    ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
    throw new CustomError(err, false, 'Reason code not recognized');
  }

  async confirmRemoveChords(){
    if (!RegisterSongService.data){
      return;
    }

    RegisterSongService.data.chords = '';
    BusyService.showBusy();
    try{
      await RegisterSongService.save();
      ToastService.successToast(this.$t('R.MES_SongChangesSaved').toString());
      bus.$emit('refreshSong');
      (this.$refs.confirmRemoveModal as Modal).hide();
      BusyService.hideBusy();
    }catch(err){
      BusyService.hideBusy();
      this.handleError(err);
    }
  }

  removeChords(){
    (this.$refs.confirmRemoveModal as Modal).show();
  }

  addChords() {
    if (!this.store.data) {
      return;
    }
    GTMService.pushCustomEvent({
      event: 'registerSongAddChords'
    });

    (this.$refs.chordEditor as EditSongChordsModal).addSongChords(
      this.store.data.songTitle,
      this.store.data.lyrictext,
      this.store.data.songWriters
    );
  }

  editChords() {
    if (!this.store.data) {
      return;
    }

    GTMService.pushCustomEvent({
      event: 'registerSongEditChords'
    });

    (this.$refs.chordEditor as EditSongChordsModal).editSongChords(
      this.store.data.songTitle,
      this.store.data.chords,
      this.store.data.songWriters.map(s => s.displayName).join(', '),
      this.store.data.lyrictext
    );
  }
}
